<template>
  <b-card title="Add POS Code">
    <b-row>
      <b-col cols="12">
        <b-form @submit.prevent="">
          <b-form-group label="POS Code" description="POS Code">
            <b-form-input
              v-model="postal"
              type="text"
              placeholder="Enter POS Code"
              maxlength="6"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Model" >
            <!-- <v-select  
              class="style-chooser"
              v-model="itemModel" 
              :options="itemCatalogue">
              <template slot="selected-option" slot-scope="option">{{option.label}}</template>
            </v-select> -->
            <!--<b-form-select v-model="itemModel" :options="itemCatalogue">-->
            <!-- </b-form-select> -->
            <b-row>
              <b-col cols="12" md="8">
                <b-form-input
                  v-model="search"
                  type="text"
                  placeholder="Enter Item Model Code"
                ></b-form-input>
              </b-col>
              <b-col cols="12" md="2">
                <b-button @click="searchItemModel" variant="primary">Search</b-button>
              </b-col>
            </b-row>
          </b-form-group>
          <b-row>
            <b-col md="6" cols="12">Item Model Selected: <p>
              <b-form-input
                  v-model="itemModel"
                  type="text"
                  disabled
                ></b-form-input></p>  </b-col>
            <b-col md="6" cols="12">description: <p>
              <b-form-input
                  v-model="description"
                  type="text"
                  disabled
                ></b-form-input></p></b-col>

          </b-row>

          <b-form-group>
            <b-row>
              <b-col cols="12" md="6" style="margin-bottom:1em">
                <div>
                  <p style="display: inline; margin-right: 25px">Skip Scan</p>
                  <b-form-checkbox
                    style="display: inline"
                    id="checkbox-1"
                    v-model="status"
                    name="checkbox-1"
                    value="true"
                    unchecked-value="false"
                  >
                  </b-form-checkbox>
                </div>
              </b-col>
              <b-col  cols="12" md="2">
                <p 
                  >Plant</p
                >
              </b-col>
              <b-col  cols="12" md="4">
                <b-form-input
                  v-model="plant"
                  disabled
                ></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>

          <b-button @click="addPostal" type="submit" variant="success"
            >Save</b-button
          >
        </b-form>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "@/axios";
import { sleep }from '@/utils/utils.js'
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    'v-select': vSelect,
  },
  data() {
    return {
      search:'',
      postal: "",
      itemModel: "",
      status: "false",
      plant: 1000,
      description:""
    };
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      // getItems: "item/getItems",
      saveBundle: "item/addItemBundle",
    }),
    searchItemModel(){
      axios
        .post("/sales-and-purchase/item-catalogue/search", {
          itemModel: this.search,
        })
        .then((res) => {
          this.itemModel = res.data.data.itemModel
          this.description = res.data.data.description

          this.$bvToast.toast("Item Model Found", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          const errorMessage = error.response.data.errors[0].message|| "Error Item Model Not Found";
          console.log(errorMessage);
          this.$bvToast.toast(errorMessage, {
            title: "Fail",
            variant: "danger",
            solid: true,
          });
        });
    },
    addPostal() {

      var itemModel = (typeof this.itemModel.itemModel == 'string')?this.itemModel.itemModel:this.itemModel;

      axios
        .post("/sales-and-purchase/drcc/postal", {
          postal: this.postal,
          itemModel: itemModel,
          skipScan: (this.status=="true")?"Y":"N"
        })
        .then(async(response) => {
          
          this.$bvToast.toast("Successfully Add POS Code", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          await sleep(1000)
          this.$router.push({ name: "post-code" });
        })
        .catch((error) => {
          const errorMessage = error.response.data.errors[0].message|| "Error Found POS Code";
          console.log(errorMessage);
          this.$bvToast.toast(errorMessage, {
            title: "Fail",
            variant: "danger",
            solid: true,
          });
        });
    },
  },
  computed: {
    // ...mapState({
    //   itemCatalogue: (state) => {
    //     return state.item.items.map((i) => {
    //       return { label: `${i.description} - ${i.itemModel} `, ...i }
    //       //return Object.assign({ value: i.itemModel, text: `${i.itemModel}` });
    //     });
    //   },
    // })
  },
};
</script>
<style scoped>
@media (max-width: 761px) {
  .hide-on-desktop { display: none !important; }
  .show-on-desktop {display: inline !important;}
}

@media (min-width: 762px) {
  .hide-on-mobile { display: none !important; }
}
</style>
